var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.currentApplicant).length
    ? _c("div", [
        _c(
          "div",
          { staticClass: "page" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("psi-page-title", {
                      attrs: {
                        title:
                          "Application for " +
                          _vm.$_.get(_vm.currentApplicant, "name", ""),
                        icon: "mdi-account",
                        breadcrumbs: []
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "subtitle",
                            fn: function() {
                              return [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "text-uppercase ml-12 py-0 mt-0"
                                  },
                                  [
                                    _vm._v(
                                      " Order # " +
                                        _vm._s(_vm.currentApplicant.order_id) +
                                        " | Group Code: " +
                                        _vm._s(
                                          _vm.$_.get(
                                            _vm.currentApplicant,
                                            "application.code",
                                            ""
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2044623135
                      )
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "3" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column d-none align-center py-2"
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          attrs: {
                            rounded: "",
                            size: "160",
                            color: "primary darken-1"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "primary--text text--lighten-2",
                              attrs: { size: "130" }
                            },
                            [_vm._v("mdi-home-city")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "h5",
                        {
                          staticClass: "font-weight-regular text-uppercase mt-1"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$_.get(
                                  _vm.currentApplicant,
                                  "application.property.company.name"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("v-col", { attrs: { cols: "9" } }, [
                  _c(
                    "div",
                    [
                      _vm.$_.has(_vm.currentApplicant, "application_details")
                        ? _c("psi-detail-card", {
                            staticClass: "mt-3",
                            attrs: {
                              items: _vm.currentApplicant.application_details,
                              "hide-heading": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "3" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column d-none align-center py-2"
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          attrs: {
                            rounded: "",
                            size: "160",
                            color: "primary darken-1"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "primary--text text--lighten-2",
                              attrs: { size: "130" }
                            },
                            [_vm._v("mdi-clipboard-account")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "h5",
                        {
                          staticClass: "font-weight-regular text-uppercase mt-1"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$_.get(_vm.currentApplicant, "name")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("v-col", { attrs: { cols: "9" } }, [
                  _c(
                    "div",
                    [
                      _c("psi-detail-card", {
                        staticClass: "mt-3",
                        attrs: {
                          items: _vm.currentApplicant.applicant_profile,
                          "hide-heading": ""
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _vm.emergencyContactItems.length
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("h4", [_vm._v("Emergency Contact")]),
                        _c("psi-simple-list", {
                          attrs: { items: _vm.emergencyContactItems }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "page",
            style: { marginTop: _vm.mode === "print" ? "80px" : "10px" }
          },
          [
            _vm.$_.has(_vm.currentApplicant, "application")
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("h4", [_vm._v("Applicants")]),
                        _c("psi-simple-table", {
                          attrs: {
                            items: _vm.currentApplicant.application.applicants,
                            headers: _vm.applicantsHeaders
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.$_.has(_vm.currentApplicant, "dependents")
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("h4", [_vm._v("Dependents")]),
                        _c("psi-simple-table", {
                          attrs: {
                            items: _vm.currentApplicant.dependents,
                            headers: _vm.dependentsHeaders
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.$_.has(_vm.currentApplicant, "pets")
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("h4", [_vm._v("Pets")]),
                        _c("psi-simple-table", {
                          attrs: {
                            items: _vm.currentApplicant.pets,
                            headers: _vm.petsHeaders
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.$_.has(_vm.currentApplicant, "vehicles")
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("h4", [_vm._v("Vehicles")]),
                        _c("psi-simple-table", {
                          attrs: {
                            items: _vm.currentApplicant.vehicles,
                            headers: _vm.vehiclesHeaders
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "page",
            style: { marginTop: _vm.mode === "print" ? "80px" : "10px" }
          },
          [
            _c("h4", [_vm._v("Residential History")]),
            _vm._l(_vm.addresses.pages[0], function(items, index) {
              return [
                _c("psi-simple-list", { key: index, attrs: { items: items } }),
                index != _vm.addresses.pages[0].length - 1
                  ? _c("v-divider", { key: "divider=" + index })
                  : _vm._e()
              ]
            })
          ],
          2
        ),
        _vm.addresses.pages.length > 1
          ? _c(
              "div",
              {
                staticClass: "page",
                style: { marginTop: _vm.mode === "print" ? "80px" : "10px" }
              },
              [
                _c("h4", [_vm._v("Residential History")]),
                _vm._l(_vm.addresses.pages[1], function(items, index) {
                  return [
                    _c("psi-simple-list", {
                      key: index,
                      attrs: { items: items }
                    }),
                    index != _vm.addresses.pages[1].length - 1
                      ? _c("v-divider", { key: "divider=" + index })
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "page",
            style: { marginTop: _vm.mode === "print" ? "80px" : "10px" }
          },
          [
            _c("h4", [_vm._v("Employment History")]),
            _vm._l(_vm.employers.pages[0], function(items, index) {
              return [
                _c("psi-simple-list", { key: index, attrs: { items: items } }),
                index != _vm.employers.pages[0].length - 1
                  ? _c("v-divider", { key: "divider=" + index })
                  : _vm._e()
              ]
            }),
            _vm.currentApplicant.incomes.length
              ? _c(
                  "div",
                  [
                    _c("h4", [_vm._v("Other Incomes")]),
                    _c("psi-simple-table", {
                      attrs: {
                        items: _vm.currentApplicant.incomes,
                        headers: _vm.incomesHeaders
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }