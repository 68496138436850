<template>
    <div v-if="Object.keys(currentApplicant).length">
        <div class="page">
            <v-row>
                <v-col cols="12">
                    <psi-page-title
                        :title="`Application for ${$_.get(
                            currentApplicant,
                            'name',
                            ''
                        )}`"
                        icon="mdi-account"
                        :breadcrumbs="[]"
                    >
                        <template v-slot:subtitle>
                            <h6 class="text-uppercase ml-12 py-0 mt-0">
                                Order # {{ currentApplicant.order_id }} | Group
                                Code:
                                {{
                                    $_.get(
                                        currentApplicant,
                                        "application.code",
                                        ""
                                    )
                                }}
                            </h6>
                        </template>
                    </psi-page-title>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3">
                    <div class="d-flex flex-column d-none align-center py-2">
                        <v-avatar rounded size="160" color="primary darken-1">
                            <v-icon
                                size="130"
                                class="primary--text text--lighten-2"
                                >mdi-home-city</v-icon
                            >
                        </v-avatar>
                        <h5 class="font-weight-regular text-uppercase mt-1">
                            {{
                                $_.get(
                                    currentApplicant,
                                    "application.property.company.name"
                                )
                            }}
                        </h5>
                    </div>
                </v-col>
                <v-col cols="9">
                    <div>
                        <psi-detail-card
                            v-if="
                                $_.has(currentApplicant, 'application_details')
                            "
                            :items="currentApplicant.application_details"
                            hide-heading
                            class="mt-3"
                        >
                        </psi-detail-card>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3">
                    <div class="d-flex flex-column d-none align-center py-2">
                        <v-avatar rounded size="160" color="primary darken-1">
                            <v-icon
                                size="130"
                                class="primary--text text--lighten-2"
                                >mdi-clipboard-account</v-icon
                            >
                        </v-avatar>
                        <h5 class="font-weight-regular text-uppercase mt-1">
                            {{ $_.get(currentApplicant, "name") }}
                        </h5>
                    </div>
                </v-col>
                <v-col cols="9">
                    <div>
                        <psi-detail-card
                            :items="currentApplicant.applicant_profile"
                            hide-heading
                            class="mt-3"
                        >
                        </psi-detail-card>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="emergencyContactItems.length">
                <v-col cols="12">
                    <h4>Emergency Contact</h4>
                    <psi-simple-list
                        :items="emergencyContactItems"
                    ></psi-simple-list>
                </v-col>
            </v-row>
        </div>

        <div
            class="page"
            :style="{ marginTop: mode === 'print' ? '80px' : '10px' }"
        >
            <v-row v-if="$_.has(currentApplicant, 'application')">
                <v-col cols="12">
                    <h4>Applicants</h4>
                    <psi-simple-table
                        :items="currentApplicant.application.applicants"
                        :headers="applicantsHeaders"
                    ></psi-simple-table>
                </v-col>
            </v-row>
            <v-row v-if="$_.has(currentApplicant, 'dependents')">
                <v-col cols="12">
                    <h4>Dependents</h4>
                    <psi-simple-table
                        :items="currentApplicant.dependents"
                        :headers="dependentsHeaders"
                    ></psi-simple-table>
                </v-col>
            </v-row>
            <v-row v-if="$_.has(currentApplicant, 'pets')">
                <v-col cols="12">
                    <h4>Pets</h4>
                    <psi-simple-table
                        :items="currentApplicant.pets"
                        :headers="petsHeaders"
                    ></psi-simple-table>
                </v-col>
            </v-row>
            <v-row v-if="$_.has(currentApplicant, 'vehicles')">
                <v-col cols="12">
                    <h4>Vehicles</h4>
                    <psi-simple-table
                        :items="currentApplicant.vehicles"
                        :headers="vehiclesHeaders"
                    ></psi-simple-table>
                </v-col>
            </v-row>
        </div>

        <div
            class="page"
            :style="{ marginTop: mode === 'print' ? '80px' : '10px' }"
        >
            <h4>Residential History</h4>
            <template v-for="(items, index) in addresses.pages[0]">
                <psi-simple-list :key="index" :items="items"> </psi-simple-list>
                <v-divider
                    v-if="index != addresses.pages[0].length - 1"
                    :key="`divider=${index}`"
                ></v-divider>
            </template>
        </div>
        <div
            class="page"
            :style="{ marginTop: mode === 'print' ? '80px' : '10px' }"
            v-if="addresses.pages.length > 1"
        >
            <h4>Residential History</h4>
            <template v-for="(items, index) in addresses.pages[1]">
                <psi-simple-list :key="index" :items="items"> </psi-simple-list>
                <v-divider
                    v-if="index != addresses.pages[1].length - 1"
                    :key="`divider=${index}`"
                ></v-divider>
            </template>
        </div>
        <div
            class="page"
            :style="{ marginTop: mode === 'print' ? '80px' : '10px' }"
        >
            <h4>Employment History</h4>
            <template v-for="(items, index) in employers.pages[0]">
                <psi-simple-list :key="index" :items="items"> </psi-simple-list>
                <v-divider
                    v-if="index != employers.pages[0].length - 1"
                    :key="`divider=${index}`"
                ></v-divider>
            </template>
            <div v-if="currentApplicant.incomes.length">
                <h4>Other Incomes</h4>
                <psi-simple-table
                    :items="currentApplicant.incomes"
                    :headers="incomesHeaders"
                ></psi-simple-table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PsiSimpleTable from "../../lib/src/components/tables/PsiSimpleTable.vue";

export default {
    name: "applicant-print",
    components: { PsiSimpleTable },
    props: {
        mode: {
            type: String,
            default: "print",
            required: false,
        },
    },
    data() {
        return {
            applicantsHeaders: [
                {
                    title: "Order ID",
                    class: "text-left",
                    key: "order_id",
                    width: 140,
                },
                {
                    title: "Type",
                    class: "text-left",
                    key: "type",
                    width: 200,
                },
                {
                    title: "Phone",
                    class: "text-left",
                    key: "phone",
                    width: 200,
                },
                {
                    title: "Email",
                    class: "text-left",
                    key: "email",
                },
            ],
            dependentsHeaders: [
                {
                    title: "First Name",
                    class: "text-left",
                    key: "first_name",
                    width: 140,
                },
                {
                    title: "Last Name",
                    class: "text-left",
                    key: "last_name",
                    width: 200,
                },
                {
                    title: "Birthdate",
                    class: "text-left",
                    key: "birthdate",
                    width: 200,
                },
                {
                    title: "Age",
                    class: "text-left",
                    key: "age",
                },
            ],
            petsHeaders: [
                {
                    title: "Type",
                    class: "text-left",
                    key: "type",
                    width: 140,
                },
                {
                    title: "Breed",
                    class: "text-left",
                    key: "breed",
                    width: 200,
                },
                {
                    title: "Weight (lbs)",
                    class: "text-left",
                    key: "weight",
                    width: 200,
                },
                {
                    title: "Neutered/Spayed",
                    class: "text-left",
                    key: "neutered",
                },
            ],
            vehiclesHeaders: [
                {
                    title: "Make",
                    class: "text-left",
                    key: "make",
                    width: 140,
                },
                {
                    title: "Model",
                    class: "text-left",
                    key: "model",
                    width: 200,
                },
                {
                    title: "Plate",
                    class: "text-left",
                    key: "plate",
                    width: 200,
                },
                {
                    title: "Year",
                    class: "text-left",
                    key: "year",
                },
            ],
            incomesHeaders: [
                {
                    title: "Source",
                    class: "text-left",
                    key: "source",
                    width: 140,
                },
                {
                    title: "Monthly Income",
                    class: "text-left",
                    key: "monthly_income",
                    format: "currency",
                    width: 100,
                },
            ],
            emergencyContactItems: [],
            addresses: {
                pages: [],
            },
            employers: {
                pages: [],
            },
        };
    },
    computed: {
        ...mapGetters("Screens", ["currentApplicant"]),
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                this.getCurrentApplicant(to.params.id);
            },
        },
        currentApplicant: {
            immediate: true,
            handler(applicant) {
                if (applicant) {
                    this.$emit(
                        "updateTitle",
                        `Application for ${applicant.name}`
                    );
                    if (this.$_.has(applicant, "emergency_contacts")) {
                        this.mapEmergencyContact(applicant);
                    }
                    if (this.$_.has(applicant, "addresses")) {
                        this.mapAddresses(applicant.addresses);
                    }
                    if (this.$_.has(applicant, "employers")) {
                        this.mapEmployers(applicant.employers);
                    }
                }
            },
        },
    },
    methods: {
        ...mapActions("Screens", ["getCurrentApplicant"]),
        mapEmergencyContact(applicant) {
            this.emergencyContactItems = [
                {
                    label: "Name",
                    value: applicant.emergency_contacts[0].name,
                },
                {
                    label: "Relationship",
                    value: applicant.emergency_contacts[0].relationship,
                },
                {
                    label: "Phone",
                    value: applicant.emergency_contacts[0].phone,
                },
                {
                    label: "Address",
                    value: applicant.emergency_contacts[0].address,
                },
            ];
        },
        mapAddresses(addresses) {
            let index = 0;
            addresses.forEach((address) => {
                const items = [
                    {
                        label: "Type",
                        value: address.type,
                    },
                    {
                        label: "Address",
                        value: this.$_.get(
                            address,
                            "primary_contact.full_address"
                        ),
                    },
                    {
                        label: `${address.label} Name`,
                        value: this.$_.get(address, "primary_contact.name"),
                    },
                    {
                        label: `${address.label} Phone`,
                        value: this.$_.get(address, "primary_contact.phone"),
                    },
                    {
                        label: `${address.label} Email`,
                        value: this.$_.get(address, "primary_contact.email"),
                    },
                    {
                        label: "Label",
                        value: address.label,
                    },
                    {
                        label: "Status",
                        value: address.status,
                    },
                    {
                        label: "Description",
                        value: address.description,
                    },
                    {
                        label: "Dates",
                        value: address.date_description,
                    },
                    {
                        label: "Payment",
                        value: address.payment_type,
                    },
                    {
                        label: "Payment Amount",
                        value: this.$dollarFormat(address.monthly_payment),
                    },
                    {
                        label: "Move In",
                        value: address.move_in_date,
                    },
                    {
                        label: "Move Out",
                        value: address.move_out_date,
                    },
                    {
                        label: "Reason for leaving",
                        value: address.reason_for_moving,
                    },
                ];

                if (index % 2 === 0) {
                    this.addresses.pages.push([]);
                }
                this.addresses.pages[this.addresses.pages.length - 1].push(
                    items
                );
                index++;
            });
        },
        mapEmployers(employers) {
            let index = 0;
            employers.forEach((employer) => {
                const items = [
                    {
                        label: "Company",
                        value: employer.company,
                    },
                    {
                        label: "Position",
                        value: this.$_.get(employer, "position"),
                    },
                    {
                        label: "Status",
                        value:
                            this.$_.get(employer, "fullpart") === "full"
                                ? "Full Time"
                                : "Part Time",
                    },
                    {
                        label: "Monthly Income",
                        value: this.$dollarFormat(
                            this.$_.get(employer, "monthly_income")
                        ),
                    },
                    {
                        label: "Duration",
                        value: this.$_.get(employer, "duration"),
                    },
                    {
                        label: "Start Date",
                        value: this.$_.get(employer, "start_date"),
                    },
                    {
                        label: "End Date",
                        value: this.$_.get(employer, "end_date", "--"),
                    },
                    {
                        label: "Address",
                        value: this.$_.get(
                            employer,
                            "primary_contact.full_address"
                        ),
                    },
                    {
                        label: `Employer Name`,
                        value: this.$_.get(employer, "primary_contact.name"),
                    },
                    {
                        label: `Employer Phone`,
                        value: this.$_.get(employer, "primary_contact.phone"),
                    },
                    {
                        label: `Employer Email`,
                        value: this.$_.get(employer, "primary_contact.email"),
                    },
                ];
                if (index % 2 === 0) {
                    this.employers.pages.push([]);
                }
                this.employers.pages[this.employers.pages.length - 1].push(
                    items
                );
                index++;
            });
        },
    },
};
</script>

<style scoped>
</style>